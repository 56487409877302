import React from 'react'
import Layout from '../../../components/DE/LayoutDE'
import PageHeader from '../../../components/PageHeader/PageHeader'
import '../../../components/ParagraphUnderPhoto/styles.css'
import Accordion from '../../../components/Accordion/Accordion'
import AccordionTemplate from '../../../components/Services/AccordionTemplate'
import Index from '../../../../content/accordion/de/sluzby'
import { graphql } from 'gatsby'
const Sluzby = ({ data: { page } }) => {
  const { image, title } = page.frontmatter
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader title={title} backgroundImage={image.childImageSharp.fluid} />
      <div className="Paragraph--Wrapper container">
        <p>
          Lassen Sie sich von richtigen Profis beraten. Wir helfen Ihnen, sich
          sowohl in der Steuergesetzgebung als auch in den Buchungsvorschriften
          zu orientieren und entlasten Sie von der Buchhaltung oder der
          Gehaltsabrechnung. Wir lösen alle Aufgaben professionell, effizient
          und mit minimalem Zeitaufwand für Sie. Wir bieten Beratung zu
          einzelnen Transaktionen sowie eine langfristige Zusammenarbeit.
        </p>
        <p>
          Zertifizierte Steuerberater und qualifizierte Buchhalter mit Erfahrung
          stehen Ihnen zur Verfügung. Darüber hinaus hat jedes Teammitglied
          seine eigene Spezialisierung, in der es sich aktiv weiterentwickelt.
          Dadurch erfahren Sie, dass sich ein echter Experte um Sie kümmert.
        </p>
      </div>
      <Accordion
        className="container"
        items={[
          {
            title: Index.dane.title,
            description: <AccordionTemplate {...Index.dane} />,
          },
          {
            title: Index.mzdy.title,
            description: <AccordionTemplate {...Index.mzdy} />,
          },
          {
            title: Index.zahranici.title,
            description: <AccordionTemplate {...Index.zahranici} />,
          },
          {
            title: Index.firemniPoradenstvi.title,
            description: <AccordionTemplate {...Index.firemniPoradenstvi} />,
          },
          {
            title: Index.ucetnictvi.title,
            description: <AccordionTemplate {...Index.ucetnictvi} />,
          },
          {
            title: Index.znaleckePosudky.title,
            description: <AccordionTemplate {...Index.znaleckePosudky} />,
          },
        ]}
      />
    </Layout>
  )
}

export default Sluzby

export const pageQuery = graphql`
  query SluzbyDE {
    page: markdownRemark(frontmatter: { slug: { eq: "dienstleistungen" } }) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
